import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import * as styles from "./Contact.module.scss";

interface IContactProps {
    theme?: string;
    heading?: string;
    paragraph?: string;
    link?: string;
}
const Contact = ({ theme }: IContactProps) => {
    const data = useStaticQuery(
        graphql`
        query contactModule {strapiContactModule {
            heading
            link
            paragraphHTML
          }}
        `
    );
    const { heading, link, paragraphHTML } = data.strapiContactModule

    return (
        <section className={[styles.wrapper, theme?.toLocaleLowerCase() === "dark" ? styles.isDark : "", "px-4"].join(" ")}>
            <h3 className={""}>{heading || "Reach out. Let's talk."}</h3>
            <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraphHTML || "Let’s talk about how we can help you leverage technology in your business." }} />
            <Link
                className="button"
                to='/contact-us'
            >
                {link || "submit"}
            </Link>
        </section>
    )
};

export default Contact;
