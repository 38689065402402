import React from "react";
import * as styles from "./BannerText.module.scss";

interface IBannerTextProps {
    background?: string;
    heading?: string;
    paragraph: string;
    hasUnderline?: Boolean;
    isFeatured?: Boolean;
}

const BannerText = ({ background, heading, paragraph, hasUnderline, isFeatured }: IBannerTextProps) => (
    <section className={[styles.wrapper, "px-3", isFeatured ? styles.isFeatured : ""].join(" ")} style={{ backgroundColor: background }}>
        <div className="mainBox">
            <div className={styles.inner}>
                {heading && <h2 className="font-serif">{heading}</h2>}

                <div className={[styles.textContainer, isFeatured ? "font-serif" : ""].join(" ")} dangerouslySetInnerHTML={{ __html: paragraph }} />

                {hasUnderline && <hr />}
            </div>
        </div>
    </section>
);

export default BannerText;
