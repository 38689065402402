import { graphql } from "gatsby";
import React, { Fragment, ReactElement } from "react";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";
import BannerText from "../../components/Shared/BannerText";
import Contact from "../../components/Shared/Contact";
import HeroImage from "../../components/Shared/HeroImage";
import RelatedContent, { IContent } from "../../components/Shared/RelatedContent";
import slugify from "../../helpers";

import * as styles from "./IndustriesPage.module.scss";
interface IIndustriesProps {
    data: GatsbyTypes.industriesQuery;
}

const IndustriesPageTemplate = ({ data }: IIndustriesProps): ReactElement => {
    const { strapiIndustriesPage } = data;

    return (
        <>
            {strapiIndustriesPage && (
                <>
                    <HeroImage
                        background={strapiIndustriesPage.hero.headingBackground}
                        backgroundMobile={strapiIndustriesPage.hero.headingBackgroundMobile}
                        heading={strapiIndustriesPage.hero.heading}
                        subHeading={strapiIndustriesPage.hero.subHeading}
                        isSerif={false}
                    />

                    <BannerText paragraph={strapiIndustriesPage.banner.paragraphHTML as string} background="#246964" />

                    <section className={styles.bodyWrapper}>
                        {strapiIndustriesPage.body.map((block: any, index: any) => (
                            <Fragment key={index}>
                                {block.type.toLowerCase() === "highlight" && (
                                    <div className={styles.highlightedBlock}>
                                        <div className="textBox px-3">
                                            <h3 dangerouslySetInnerHTML={{ __html: block.paragraphHTML || "" }}></h3>
                                            {block.image && <span className={styles.highlightedIcon} dangerouslySetInnerHTML={{ __html: block.image || "" }} />}
                                        </div>
                                    </div>
                                )}
                                {block.type.toLowerCase() === "paragraph" && (
                                    <div className={`${styles.textBoxWrapper} textBox`}>
                                        {block.heading && <h3 className={styles.textSubtitle}>{block.heading}</h3>}
                                        <div dangerouslySetInnerHTML={{ __html: block.paragraphHTML || "" }}></div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </section>

                    {strapiIndustriesPage.industries.visible && strapiIndustriesPage.industries.relation && (
                        <RelatedContent
                            heading={strapiIndustriesPage.industries.heading}
                            content={
                                strapiIndustriesPage?.industries.relation.map(relatedItem => {
                                    return {
                                        heading: relatedItem?.hero.heading,
                                        image: relatedItem?.hero.heroThumbnail,
                                        slug: `/industries/${slugify(relatedItem?.title as string)}`,
                                    };
                                }) as IContent[]
                            }
                        />
                    )}

                    <Contact />
                </>
            )}
        </>
    );
};

export default IndustriesPageTemplate;

export const query = graphql`
    query industries($id: String!) {
        strapiIndustriesPage(id: { eq: $id }) {
            hero {
                heading
                headingBackground {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                headingBackgroundMobile {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                subHeading
            }
            banner {
                paragraphHTML
            }
            body {
                type
                paragraphHTML
                image
                heading
            }
            industries {
                visible
                heading
                relation {
                    title
                    hero {
                        heading
                        heroThumbnail {
                            alternativeText
                            file {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id='site-title'>{seo.title}</title>
            <meta id='desc' name="description" content={seo.description} />
            <meta id='tw-card' name="twitter:card" content={seo.twitter.card } />
            <meta id='tw-site' name="twitter:site" content={seo.twitter.site} />
            <meta id='tw-creator' name="twitter:creator" content={seo.twitter.creator } />
            <meta id='tw-title' name="twitter:title" content={seo.twitter.title } />
            <meta id='tw-desc' name="twitter:description" content={seo.twitter.description } />
            <meta id='tw-img' name="twitter:image" content={seo.twitter.image.url } />
            <meta id='fb-type' property="og:type" content={seo.facebook.type } />
            <meta id='fb-title' property="og:title" content={seo.facebook.title } />
            <meta id='fb-desc' property="og:description" content={seo.facebook.description } />
            <meta id='fb-url' property="og:url" content={seo.facebook.url } />
            <meta id='fb-img' property="og:image" content={seo.facebook.image.url } />
        </SiteMetadataHead>
    );
}