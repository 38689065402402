import React, { PropsWithChildren } from "react";
import { withPrefix } from "gatsby";
import useSiteMetadata from "../../Layout/SiteMetadata";

export function SiteMetadataHead({ children }: PropsWithChildren) {
    const { title, description, twitter, og } = useSiteMetadata();

    return (
        <>
            <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix("/")}favicon/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" href={`${withPrefix("/")}favicon/favicon-32x32.png`} sizes="32x32" />
            <link rel="icon" type="image/png" href={`${withPrefix("/")}favicon/favicon-16x16.png`} sizes="16x16" />
            <link rel="mask-icon" href={`${withPrefix("/")}favicon/safari-pinned-tab.svg`} color="#102641" />
            <link rel="stylesheet" href="https://use.typekit.net/agb0rqu.css" />
            <meta name="theme-color" content="#102641" />
            <title id='site-title'>{title}</title>
            <meta id='desc'name="description" content={description} />
            <meta id='tw-card'name="twitter:card" content={twitter.card} />
            <meta id='tw-site'name="twitter:site" content={twitter.site} />
            <meta id='tw-creator'name="twitter:creator" content={twitter.creator} />
            <meta id='tw-title'name="twitter:title" content={twitter.title} />
            <meta id='tw-desc'name="twitter:description" content={twitter.description} />
            <meta id='tw-img'name="twitter:image" content={twitter.image} />
            <meta id='fb-type' property="og:type" content={og.type} />
            <meta id='fb-title' property="og:title" content={og.title} />
            <meta id='fb-desc' property="og:description" content={og.description} />
            <meta id='fb-url' property="og:url" content={og.url} />
            <meta id='fb-img' property="og:image" content={og.image} />
            {children}
        </>
    );
}